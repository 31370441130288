<template>
  <v-row>
    <v-col cols="6">
      importar DOCX
      <v-btn color="primary" small @click="importar">importar</v-btn>
    </v-col>
    <v-col cols="6">
      <input type="file" @change="parseDocxFile" />
      <v-file-input show-size label="Planilha" @change="parseDocxFile"></v-file-input>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from 'moment';
import XLSX from 'xlsx';
import VueObjectView from 'vue-object-view';
import docx4js from "docx4js"

//import { plan01 } from "./planJSON01.js"
//import { recursosR } from "./recursos-raw2.js"
//import { motivos } from "./motivos.js"

export default {
  name: 'xlsx',
  components: { VueObjectView },
  data(){
    return {
      abas: [],
      abaSelecionada: "",
      linhas: null,
      ucs: [], ucsObj: {}, ucsByName: {}, ucsNome: {}, ucsCodigo: {},
      workbook: null, reader: null,
      refreshKey: 0,
      areas: [], areasObj: {}, areasSigla: {}, areasNome: {},
      profsByCPF: {}, profsByNome: {}, pessoas: {},
      recursos: {},
      questoes: {},
    }
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');
    //console.log("motivos",motivos);
    //console.log("recursos",recursos);

    //rdb.ref('/curriculo/areas').on('value', function(snapshot) {
    //  self.areas = snapshotValToArray(snapshot.val());
    //  self.areasObj = snapshot.val();
    //  console.log("self.areas",self.areas);
    //});

    //this.loadUcs();
    //this.loadPessoas();
  },
  methods: {

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      this.workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = this.workbook.SheetNames
      this.abas = sheetName;
      console.log(sheetName);
      var sheet = this.workbook.Sheets[sheetName[abaIdx]]
      this.abaSelecionada = sheetName[0];
      console.log(sheet);
      this.linhas = XLSX.utils.sheet_to_json(sheet, {raw: false});
      console.log(this.linhas);
      this.refreshKey += 1;
      this.$forceUpdate();
    },

    parseExcelFile1(file) {
      var self = this;
      console.log("parseExcelFile1",file);
      if(file == undefined) return;
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseDocxFile(file) {
      var self = this;
      console.log("parseDocxFile",file);
      console.log("elem.target.files[0]",file.target.files[0]);
      if(file == undefined) return;
      console.log("docx4js",docx4js);

      docx4js.load(file.target.files[0]).then((docx) => {
        console.log("docx",docx);
        docx.render(function createElement(type,props,children){
          console.log({type,props,children});
        })

        docx.parse(handler)
      });

    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    loadPessoas() {
      var self = this;
      rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
        self.pessoas = snapshot.val();
        console.log("self.pessoas",self.pessoas);
        console.log("qtde self.pessoas",Object.keys(self.pessoas).length);
        self.profsByCPF = {};
        for(var key in self.pessoas) {
          if(self.pessoas[key].cpf != undefined) {
            self.profsByCPF[self.pessoas[key].cpf] = self.pessoas[key];
          }
          var nomeprof = self.pessoas[key].nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.profsByNome[nomeprof] = self.pessoas[key];
        }
        console.log("self.profsByNome",self.profsByNome);
        console.log("qtde self.profsByNome",Object.keys(self.profsByNome).length);
        console.log("self.profsByCPF",self.profsByCPF);
        console.log("qtde self.profsByCPF",Object.keys(self.profsByCPF).length);
      });
    },

    loadAreas() {
      var self = this;
      rdb.ref('/curriculo/areas').on('value', function(snapshot) {
        self.areas = snapshotValToArray(snapshot.val());
        self.areasObj = snapshot.val();
        for(var key in self.areasObj) {
          self.areasSigla[self.areasObj[key].sigla] = key;
          self.areasNome[self.areasObj[key].nome.trim().toUpperCase()] = key;
        }
        console.log("self.areas",self.areas);
        console.log("self.areasSigla",self.areasSigla);
        console.log("self.areasNome",self.areasNome);
      });
    },

    loadUcs() {
      var self = this;
      rdb.ref('/curriculo/ucs').on('value', function(snapshot) {
        self.ucs = snapshotValToArray(snapshot.val());
        self.ucsObj = snapshot.val();
        for(var key in self.ucsObj) {
          var nome = self.ucsObj[key].nome.trim().toUpperCase();
          var vet = nome.split("(");
          if(vet.length>1) {
            nome = vet[0].trim();
          }
          nome = nome.trim().toUpperCase();
          if(nome.charAt(nome.length-1) == ".") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ";") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ",") { nome = nome.substr(0,nome.length-1); };
          nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.ucsByName[nome] = self.ucsObj[key];
        }
        console.log("self.ucsByName", self.ucsByName);
        console.log("self.ucs",self.ucs);
      });
    },

    getUCByName(nome) {
      var bestValue = 0;
      var bestNome = "";
      for(var key in this.ucsByName) {
        var value = stringSimilarity.compareTwoStrings(nome,key);
        if(value > bestValue) {
          bestValue = value;
          bestNome = key;
        }
      }
      if(bestValue < 0.9) {
        console.log("["+bestValue+"] ruim: "+nome+" ===> "+bestNome+" ("+this.ucsByName[bestNome].nome+")");
      }
      return this.ucsByName[bestNome];
    },

    importar() {
      console.log("importar");
      console.log("docx4js",docx4js);
      //docx4js.docx.load(elem.target.files[0]).then((docx) => {

      docx4js.load("~/test.docx").then(function(docx) {
        console.log(docx);
      });

    }

  }
}
</script>

<style scoped>


</style>
